.card_modulos{
  margin-top: 20px !important;
  border: 1px solid #c0c0c0;
  border-top-left-radius: 10px;
  border-bottom-left-radius: 10px;
  overflow: hidden;

  .item_modulo{
    padding: 10px 15px !important;
    margin-bottom: 5px;
    cursor: pointer;
    text-transform: uppercase;
    font-weight: 600;
  }

  .item_modulo.selecionado{
    background-color: #063e11;
    color: #FFF;
  }

  .item_modulo:hover{
    background-color: #063e11;
    color: #FFF;
  }
}

.card_questions{
  margin-top: 20px !important;
  border: 1px solid #c0c0c0;
  border-top-right-radius: 10px;
  border-bottom-right-radius: 10px;
  overflow: hidden;
  padding: 5px 5px !important;
}

.accordion-custom {
  border: 1px solid #c0c0c0;
  border-radius: 10px !important;
  box-shadow: none;
  transition: all 0.3s ease;
  margin-bottom: 5px;
}

.accordion-custom.Mui-expanded {
  background-color: #f0f0f0; /* Fundo diferente quando aberto */
  border: 1px solid #063e11; /* Cor da borda diferente */
}