@font-face {
  font-family: 'Gotham-Light';
  src: local('Gotham-Light'), url('./../fonts/Gotham-Light.ttf') format('truetype');
}

html,
body {
  padding: 0;
  margin: 0;
  // font-family: -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen, Ubuntu,
  //   Cantarell, Fira Sans, Droid Sans, Helvetica Neue, sans-serif;
  line-height: 1.6;
  font-size: 18px;
  background: #eee;
}

a {
  text-decoration:none !important; 
}

* {
  box-sizing: border-box;
  font-family: Gotham-Light, Arial, serif;
}

a {
  color:#000 ;
  text-decoration: none;
}

a:hover {
  text-decoration: underline;
}

img {
  max-width: 100%;
  display: block;
}

:root{
  --bg-dark: #1A2F3A;
  --logo-height: 100px;
  --header-height: 50px;
  --aside-width: 175px;
  --footer-height: 50px;
  --menu-top-height: 70px;

  --shadow: 
      0 2px 23px 0 rgba(0, 0, 0, 0.1),
      0 2px 49px 0 rgba(0, 0, 0, 0.06);
}

*{
  box-sizing: border-box;
}

@media (min-width: 701px) {
  /* Layout em Grid */
  .app-menu-open {
    margin: 0px;
    display: grid;
    grid-template-columns: 20% 80%;
    grid-template-rows: 100%;
    grid-template-areas: "menu content";
    min-height: 100vh;
    height: 100%;
    background-color:#eee
  }
  
  .app-menu-closed {
    margin: 0px;
    display: grid;
    grid-template-columns: 0% 100%;
    grid-template-rows: 100%;
    grid-template-areas: "menu content";
    min-height: 100vh;
    height: 100%;
    background-color:#eee;
  }
}

@media (max-width: 700px) {
  
  .app-menu-open {
    margin: 0px;
    display: grid;
    grid-template-columns: 100% 0%;
    grid-template-rows: 100%;
    grid-template-areas: "menu content";
    min-height: 100vh;
    height: 100%;
    background-color:#eee
  }
  
  .app-menu-closed {
    margin: 0px;
    display: grid;
    grid-template-columns: 0% 100%;
    grid-template-rows: 100%;
    grid-template-areas: "menu content";
    min-height: 100vh;
    height: 100%;
    background-color:#eee;
  }
}

aside.menu{
  grid-area: nav;
}

main.content{
  grid-area: content;
  // overflow: hidden;
}

@media (max-width: 700px) {
  .app-menu-open main.content{
    display: none;
  }
}

main > div {
  background-color: #FFF;
  box-shadow: 0px 0px 15px #0004;
  border-radius: 25px;
}


.h-90 {
  min-height: 95%;
}

.p-3{
  padding: 1rem!important;
} 

.mt-3{
  margin-top: 1rem!important;
}

.container, .container-fluid, .container-lg, .container-md, .container-sm, .container-xl {
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;
  margin-bottom: 10px;
}

.MuiTableSortLabel-root.MuiTableSortLabel-active {
  color: #FFF !important;
}

.MuiTableSortLabel-root.MuiTableSortLabel-active.MuiTableSortLabel-root.MuiTableSortLabel-active .MuiTableSortLabel-icon {
  color:#FFF !important;
  opacity: 1;
}
.MuiTableSortLabel-root:hover {
  color: #FFF !important;
}

.MuiPaper-elevation1 {
  // border: 1px solid #000;
}

.MuiTableCell-paddingNone {
  padding: 10px !important;
}

.MuiTableCell-root {
  border: 1px solid rgba(224, 224, 224, 1) !important;
}

.input{
  width: 100%;
  background: #FFF;
  border-radius: 5px;
}

tbody .MuiTableCell-sizeSmall {
  padding: 0px 10px 0px 10px !important;
}

.imagemTable{
  width: 24px; 
  height: 24px;
  margin-left: calc(50% - 12px);
}

.card{
  margin-top: 20px;
  border: 1px solid #000;
  padding: 10px;
  border-radius: 20px;
}

.buttonAdd{
  background-color: green !important;
  color: #FFF !important;
  width: 40px;
  height: 40px;
}

.buttonRemove{
  background-color: darkred !important;
  color: #FFF !important;
  width: 40px;
  height: 40px;
}

.alignCenter{
  text-align: center;
}
.row-grey {
  background: #dbdada;
}

.MuiFormControl-marginNormal {
    margin-top: 0px !important;
    margin-bottom: 0px !important;
}

.error{
  font-size: 12px;
  color: red;
  position: relative;
  top: -3px;
}

.aviso{
  font-size: 16px !important;
  color: red;
}

table .MuiSvgIcon-root {
 font-size: 1.4rem !important;
}

table .MuiIconButton-root{
  padding: 2px;
}

.MuiOutlinedInput-inputMarginDense {
    padding-top: 12.5px !important;
    padding-bottom: 10.5px !important;
}

td button:last-child{
  margin-left: 10px;
}

.progress{
  height: 20px !important;
}

.t-a-r{
  text-align: right;
}
.t-a-c{
  text-align: center;
}
.t-a-l{
  text-align: left;
}

.cardRel{
  border: 1px solid;
  padding: 10px;
}

.f-s-14{
  font-size:14px
}

hr{
  margin: 0
}

.progressCircular{
  width: 40px;
  height: 40px;
  text-align: center;
  position: absolute;
  top: 50%;
  left: 50%;
  margin-left: -20px;
  margin-top: -20px;
}

.loadingTela{
  width: 100%;
  position: absolute;
  top: 0;
  background-color: rgba(255, 255, 255, 0.7);
  z-index: 999;
  left: 0;
  text-align: center
}

.progressText{
  width: 400px;
  height: 20px;
  text-align: center;
  position: absolute;
  top: 56%;
  left: 50%;
  margin-left: -200px;
  margin-top: -10px;
}

// .makeStyles-paper-37{
//   border: 2px solid #2c4e82 !important
// }

.align-right{
  text-align: right;
}

.align-center{
  text-align: center;
}

.align-left{
  text-align: left;
}

.inputCenter div input{
  text-align: center !important;
}

.margin0{
  margin: 0 !important;
}

.mg-top-7{
  margin-top: 7px;
}
.tituloConfirmacao{
  margin: 0px;
  text-transform: uppercase;
  color: #a98b15;
  margin-bottom: 0px !important;
  margin-top: 0px !important;
  font-family: Gotham-Light, Arial, serif;
  text-transform: uppercase;
  font-size: 26px;
}

.tituloCancelamento{
  margin: 0px;
  text-transform: uppercase;
  color: red;
  margin-bottom: 0px !important;
  margin-top: 0px !important;
  font-family: Gotham-Light, Arial, serif;
  text-transform: uppercase;
  font-size: 36px;
}

.MuiTableCell-sizeSmall {
  padding: 6px 16px 6px 16px !important;
}
.color-grey{
  color: grey
}

.MuiTableRow-root {
  color: inherit;
  display: table-row;
  outline: 0;
  vertical-align: middle;
  height: 40px;
}

.MuiButton-containedSecondary {
  color: #fff !important;
  background-color: #f50057 !important;
}

.MuiAutocomplete-hasPopupIcon .MuiAutocomplete-inputRoot[class*="MuiOutlinedInput-root"], .MuiAutocomplete-hasClearIcon .MuiAutocomplete-inputRoot[class*="MuiOutlinedInput-root"] {
  padding-right: 39px;
}

.MuiOutlinedInput-input {
  // padding: 18.5px 14px !important;
  // height: 10px !important;
  font-size: 14px !important;
}

.MuiAutocomplete-inputRoot[class*="MuiOutlinedInput-root"] .MuiAutocomplete-input {
  padding: 9.5px 4px !important;
}

.MuiButton-containedSizeSmall {
  padding: 4px 10px;
  font-size: 0.8125rem;
}

.MuiButton-fullWidth {
  width: 100%;
}

.MuiButton-containedPrimary {
  color: #fff !important;
  background-color: #3f51b5 !important;
}

.MuiInputBase-input.Mui-disabled {
  opacity: 1;
}

.rdw-editor-toolbar{
  border-bottom: 1px solid #000 !important;
}

.cardEditor{
  border: 1px solid #000 !important;

  .DraftEditor-root{
    max-height: 400px;
    overflow: auto;
  }
}

.MuiInputBase-inputMultiline {
  height: auto !important;
  resize: none;
  padding: 0;
}

.MuiButton-contained.Mui-disabled {
  color: rgba(0, 0, 0, 0.26) !important;
  box-shadow: none;
  background-color: rgba(0, 0, 0, 0.12) !important;
}

.MuiFormControl-root{
  width: 100%;
}

.inputCpfCnpj{
  width: 100%;
  padding: 18.5px 14px !important;
  height: 57px;
  border-color: rgba(0, 0, 0, 0.23);
  border-radius: 5px;
}

.borderTable{
  border: 2px solid #000;
  width: 100%;
}

.lista tr:first-child {
  // border-bottom: 2px solid #ddd;
  border-top: none;
}

.lista tr:nth-child(odd) {
  background-color: #FFF;
}
.lista tr:nth-child(even) {
  background-color:#DCDCDC;
}

// .lista tr:last-child {
//   background:#ddd;
// }

.borderTable tr th{
  border: 1px solid #063e11;
  background-color: #063e11;
  color: #FFF;
  text-align: center;
}

.borderTable td {
  font-size: 14px;
  font-weight: 400 !important;
  text-align: center;
}

.positivo{
  margin-top: 10px !important;
  color: green;
}

.negativo{
  color: red
}

.agrupador{
  font-size: 16px !important;
  font-weight: bold;
} 

.MuiTableCell-root {
  line-height: 1 !important;
}

th{
  span{
    font-size: 12px !important;
  }
}

legend{
  font-size: 14px !important
}

.card-flag-fornecedor{
  border: 1px solid #f50057;
  width: 100px;
  padding: 5px 2px;
  background-color: #f50057;
  color: #FFF;
  border-radius: 5px;
  font-weight: 600;
  text-align: center;
  float: left;
  margin-left: 10px;
  margin-top: 2px;
  margin-bottom: 2px;
  height: 20px;
  font-size: 12px;
}

.card-flag-cliente{
  border: 1px solid #3f51b5;
  width: 100px;
  padding: 5px 2px;
  background-color: #3f51b5;
  color: #FFF;
  border-radius: 5px;
  font-weight: 600;
  text-align: center;
  float: left;
  margin-top: 2px;
  margin-bottom: 2px;
  margin-left: 10px;
  font-size: 12px;
  height: 20px;
}

.card-flag-funcionario{
  border: 1px solid #32CD32;
  width: 100px;
  padding: 5px 2px;
  background-color: #32CD32;
  color: #FFF;
  border-radius: 5px;
  font-weight: 600;
  text-align: center;
  float: left;
  margin-top: 2px;
  margin-bottom: 2px;
  margin-left: 10px;
  height: 20px;
  font-size: 12px;
}

.card-flag-responsável{
  border: 1px solid #4B0082;
  width: 100px;
  padding: 5px 2px;
  background-color: #4B0082;
  color: #FFF;
  border-radius: 5px;
  font-weight: 600;
  text-align: center;
  float: left;
  margin-top: 2px;
  margin-bottom: 2px;
  margin-left: 10px;
  font-size: 12px;
  height: 20px;
}

.card-flag-revendedor{
  border: 1px solid #ed6c02;
  width: 100px;
  padding: 5px 2px;
  background-color: #ed6c02;
  color: #FFF;
  border-radius: 5px;
  font-weight: 600;
  text-align: center;
  float: left;
  margin-top: 2px;
  margin-bottom: 2px;
  margin-left: 10px;
  font-size: 12px;
  height: 20px;
}

.card-flag-representante{
  border: 1px solid #000;
  width: 125px;
  padding: 5px 2px;
  background-color: #000;
  color: #FFF;
  border-radius: 5px;
  font-weight: 600;
  text-align: center;
  float: left;
  margin-top: 2px;
  margin-bottom: 2px;
  margin-left: 10px;
  font-size: 12px;
  height: 20px;
}

.card-flag-grupoeconomico{
  border: 1px solid #C0C0C0;
  width: 135px;
  padding: 5px 2px;
  background-color: #C0C0C0;
  color: #000;
  border-radius: 5px;
  font-weight: 600;
  text-align: center;
  float: left;
  margin-top: 2px;
  margin-bottom: 2px;
  margin-left: 10px;
  font-size: 12px;
  height: 20px;
}

.card-flag-unidade{
  border: 1px solid #DAA520;
  width: 90px;
  padding: 5px 2px;
  background-color: #DAA520;
  color: #000;
  border-radius: 5px;
  font-weight: 600;
  text-align: center;
  float: left;
  margin-top: 2px;
  margin-bottom: 2px;
  margin-left: 10px;
  font-size: 12px;
  height: 20px;
}

.clicksistemas{
  position: fixed;
  bottom: 0px;
  right: 10px;
  font-size: 12px;
}

// .renderUnidades{
//   label{
//     background-color: transparent !important;
//     color: #FFF !important
//   }
// }

.franquias{
  label{
    background-color: transparent !important;
    color: #FFF !important
  }
}

label{
  // background-color: #FFF !important;
  // color: #000 !important
}


.titulo{
  font-family: Gotham-Light, Arial, serif;
  text-transform: uppercase;
}

@media (min-width: 701px) {
  .ocultar-desktop{
    display: none !important;
  }
}

.avisoAlerta{
  font-size: 14px;
  color: red;
  margin: 2px;
}

.MuiAppBar-colorPrimary{
  background-color: #063e11 !important;
}

.input.selecionado fieldset{
  height: 45px !important;
}

.input.nao_selecionado fieldset{
  // height: 45px !important;
}

.input.nao_selecionado svg{
  margin-top: -9px !important;
}

.input.selecionado svg{
  margin-top: -10px !important;
}

.input.selecionado .MuiListItemIcon-root{
  color: rgba(0, 0, 0, 0.54);
  display: block;
  min-width: 30px !important;
  flex-shrink: 0;
  float: left;
  margin-top: -15px;
}

.input.selecionado .MuiListItemText-root{
  margin-top: -11px;
}

.inputConta.selecionado fieldset{
  // height: 50px !important;
  // margin-top: 16px;
}

.inputConta.nao_selecionado fieldset{
  // height: 45px !important;
  // margin-top: 9px;
}

.inputConta.selecionado .MuiListItemIcon-root{
  color: rgba(0, 0, 0, 0.54);
  display: block;
  min-width: 30px !important;
  flex-shrink: 0;
  float: left;
  margin-top: 2px;
}

.inputConta.selecionado .MuiListItemText-root{
  margin-top: 3px;
}

.MuiAutocomplete-inputRoot{
  fieldset{
    // height: 45px;
  }
}

#radio {
  label{
    margin: 0
  }

  .MuiFormControlLabel-label{
    font-size: 14px;
  }

  .MuiSvgIcon-root{
    font-size: 20px;
  }
}

.mg-top--10{
  margin-top: -10px !important;
}

.mg-top--20{
  margin-top: -20px !important;
}

.MuiTableRow-head {
  background-color: #063e11 !important;
}

.avisoConciliacao{
  color: red
}

.MuiTablePagination-caption, .MuiTablePagination-select{
  font-size: 12px !important;
}

.MuiAutocomplete-inputRoot{
  height: 40px !important;
}

.paperPix{
  width: 400px !important;
  height: 420px !important;
  padding: 0 !important;
  position: absolute;
  box-shadow: 0px 3px 5px -1px rgba(0,0,0,0.2), 0px 5px 8px 0px rgba(0,0,0,0.14), 0px 1px 14px 0px rgba(0,0,0,0.12);
  list-style: none;
  margin-top: 30px;
  overflow-y: auto;
  margin-left: calc(50% - 200px) !important;
  background-color: #fff;

  p{
    margin: 0px !important;
  }

  img{
    width: 80% !important; 
    margin-left: 10% !important;
  }
}

.MuiInputLabel-root{
  color: #000 !important;
}

th, td{
  font-weight: bold !important;
}

th, td{
  span{
    font-weight: bold !important;
  }
}

thead th{
  text-transform: uppercase;
}

legend{
  // font-weight: bold !important;
  color: #000 !important;
}

.signatureCanvas{
  border: 1px solid #000;
  width: 100%;
  min-height: 300px;
}

.paperUploadPlanilha{
  width: 600px;
  padding: 10px 15px;
  position: absolute;
  box-shadow: 0px 3px 5px -1px rgba(0,0,0,0.2), 0px 5px 8px 0px rgba(0,0,0,0.14), 0px 1px 14px 0px rgba(0,0,0,0.12);
  list-style: none;
  margin-top: 30px;
  overflow-y: auto;
  margin-left: calc(50% - 300px);
  background-color: #fff;
}

@media (max-width: 700px) {
  .ocultar-mobile{
    display: none !important;
  }

  h1.titulo{
    font-size: 2em;
    text-align: center;
  }  

  h2.titulo{
    font-size: 1.8em;
  }  

  h3.titulo{
    font-size: 1.5em;
  }  

  h4.titulo{
    font-size: 1.3em;
  }  

  h5.titulo{
    font-size: 1.1em;
  }  

  .makeStyles-paperPix-3{
    width: 90% !important;
    margin-left: 5%;
  }

  .paperPix{
    width: 90% !important; 
    height: 400px !important;
    padding: 10px 5px !important;
    position: absolute;
    box-shadow: 0px 3px 5px -1px rgba(0,0,0,0.2), 0px 5px 8px 0px rgba(0,0,0,0.14), 0px 1px 14px 0px rgba(0,0,0,0.12);
    list-style: none;
    margin-top: 30px;
    overflow-y: auto;
    margin-left: 5% !important;
    background-color: #fff;

    img{
      width: 100%;
    }
  }

  .paperUploadPlanilha{
    width: 90%;
    padding: 10px 15px;
    position: absolute;
    box-shadow: 0px 3px 5px -1px rgba(0,0,0,0.2), 0px 5px 8px 0px rgba(0,0,0,0.14), 0px 1px 14px 0px rgba(0,0,0,0.12);
    list-style: none;
    margin-top: 30px;
    overflow-y: auto;
    margin-left: 5%;
    background-color: #fff;
  }
}

.botao_ajuda_login{
  width: 50px !important;
  height: 50px !important;
  position: fixed;
  bottom: 10px;
  right: 10px;
  color: #FFF;
  cursor: pointer;
}

.botao_ajuda_login:hover{
  color: #8bc34a;
}

.botao_ajuda{
  width: 50px !important;
  height: 50px !important;
  position: fixed;
  bottom: 10px;
  right: 10px;
  color: #8bc34a;
  cursor: pointer;
  background-color: #FFF;
  border-radius: 50%;
  z-index: 999;
}

.botao_ajuda:hover{
  color: #063e11;
}

.cardInfo{
  color: #000; 
  border: 1px solid #C0C0C0; 
  padding: 10px; 
  border-left: 10px solid #C0C0C0;
  margin-bottom: 15px;

  p{
    margin: 10px 0 0 0;
    font-size: 12px;
  }
  
  h5{
    margin: 0;
    font-weight: bold;
    font-size: 16px;
  }
}

.MuiAlert-filledError{
  font-weight: bold !important;
}

#view .css-1n4twyu-MuiInputBase-input-MuiOutlinedInput-input.Mui-disabled {
  color: #000 !important;
  -webkit-text-fill-color: #000 !important;
}

#view .css-1qxw4jt-MuiInputBase-root-MuiOutlinedInput-root.Mui-disabled{
  color: #000 !important;
  -webkit-text-fill-color: #000 !important;
}

.MuiTab-root {
  min-width: 20% !important;
  color: #FFF !important;

  span{
    font-size:12px;
  }
}

.MuiTableSortLabel-icon{
  color: #FFF !important;
}

.thumbnails-container{
  border: none !important;
  padding: 0 !important;
  margin: 0 !important;

  .thumbnail-container {
    margin: 0 !important;
  }
}

.dropzone{
  border: 1px dashed #000;
  cursor: pointer;
  transition: height 0.2s ease;

  p, em{
    display: flex;
    color: #000;
    align-items: center;
    justify-content: center;
    padding: 5px 0 0 0;
    font-weight: bold;
    margin: 0
  }
}

.dropzone_error{
  border: 1px dashed red;
  cursor: pointer;
  transition: height 0.2s ease;

  p, em{
    display: flex;
    color: #000;
    align-items: center;
    justify-content: center;
    padding: 5px 0 0 0;
    font-weight: bold;
    margin: 0
  }

  p.erro{
    color: red;
  }
}

#view select {
  -webkit-text-fill-color: #000 !important;
}